import * as React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Img from "gatsby-image"

import { MDXRenderer } from "gatsby-plugin-mdx"

const InsertBlock = () => {
  return (
    <div>
      <strong>this is the inserted block</strong>
    </div>
  )
}

// const BlogPostTemplate = ({ data, context, location }) => {
const BlogPostTemplate = ({
  data: { authorImage, coverImage },
  pageContext: { nextPost, page, previousPost },
  location,
}) => {
  // const post = data.post
  // const siteTitle = data.site.siteMetadata?.title || `Title`
  // const { previous, next } = data
  const siteTitle = "DC"
  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        // title={post.frontmatter.title}
        title={page.title}
        description={page.description || page.excerpt}
      />
      <article
        // className="blog-post flex flex-col items-center mt-5"
        // className="blog-post md:flex md:flex-col md:items-center mt-5 "
        className="blog-post md:flex md:flex-col mt-5 "
        itemScope
        itemType="http://schema.org/Article"
      >
        <header>
          <h1 itemProp="headline">{page.title}</h1>
          <p>{page.date}</p>
          <p>{page.category.title}</p>
          <p>
            <Img
              fluid={authorImage.localFile.childImageSharp.fluid}
              className="w-10 h-10 rounded-full"
              fadeIn={false}
            />
            <span>{page.author.name}</span>
            <br />
            <span>{page.author.title}</span>
          </p>
        </header>
        {/* <section
          className="prose md:prose-xl"
          dangerouslySetInnerHTML={{ __html: post.html }}
          itemProp="articleBody"
        /> */}
        <section
          className="prose md:prose-xl max-w-xs lg:max-w-7xl"
          itemProp="articleBody"
        >
          <MDXRenderer>{page.content.markdownNode.childMdx.body}</MDXRenderer>
        </section>
        <hr />
        <footer>
          <Bio />
        </footer>
      </article>
      <nav className="blog-post-nav">
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {/* <Link to={"/posts/".concat(previousPost.slug)} rel="prev"> */}
            {previousPost && (
              <Link
                to={"/".concat(
                  previousPost.category.slug.concat(
                    "/".concat(previousPost.slug)
                  )
                )}
                rel="prev"
              >
                ← {previousPost.title}
              </Link>
            )}
          </li>
          <li>
            {nextPost && (
              <Link
                to={"/".concat(
                  nextPost.category.slug.concat("/".concat(nextPost.slug))
                )}
                rel="next"
              >
                {/* <Link to={"/posts/".concat(nextPost.slug)} rel="next"> */}
                {nextPost.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  fragment AssetFields on GraphCMS_Asset {
    id
    localFile {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
  query BlogPostQuery($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    authorImage: graphCmsAsset(
      authorAvatar: {
        elemMatch: { posts: { elemMatch: { id: { in: [$id] } } } }
      }
    ) {
      ...AssetFields
    }
    coverImage: graphCmsAsset(
      coverImagePost: { elemMatch: { id: { eq: $id } } }
    ) {
      ...AssetFields
    }
  }
`

// export const PageQuery = graphql`
//   query BlogPostBySlug($id: String!) {
//     site {
//       siteMetadata {
//         title
//       }
//     }

//     post: graphCmsPost(id: { eq: $id }) {
//       id
//       slug
//       title
//       date
//       excerpt
//       category {
//         id
//         title: name
//       }
//       author {
//         id
//         name
//       }
//       seo {
//         id
//         image {
//           url
//         }
//       }
//       content {
//         markdownNode {
//           childMdx {
//             body
//           }
//         }
//       }
//     }
//   }
// `

// export const pageQuery = graphql`
//   query BlogPostBySlug(
//     $id: String!
//     $previousPostId: String
//     $nextPostId: String
//   ) {
//     site {
//       siteMetadata {
//         title
//       }
//     }
//     markdownRemark(id: { eq: $id }) {
//       id
//       excerpt(pruneLength: 160)
//       html
//       frontmatter {
//         title
//         date(formatString: "MMMM DD, YYYY")
//         description
//       }
//     }
//     previous: markdownRemark(id: { eq: $previousPostId }) {
//       fields {
//         slug
//       }
//       frontmatter {
//         title
//       }
//     }
//     next: markdownRemark(id: { eq: $nextPostId }) {
//       fields {
//         slug
//       }
//       frontmatter {
//         title
//       }
//     }
//   }
// `
